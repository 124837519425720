import styled from 'shared/theme'
import React, { PropsWithChildren } from 'react'
import Div100vh from 'react-div-100vh'

const Container = styled(Div100vh)`
    width: 100%;
    max-width: 768px;
    box-sizing: border-box;
    margin: auto;
`

const Wrapper: React.FC<PropsWithChildren> = ({ children }) => {
    return <Container>{children}</Container>
}

export default Wrapper
