import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import Wrapper from 'components/Container'

import Router from './Router'
import { theme } from './shared/theme'

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Wrapper>
                    <Router />
                </Wrapper>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default App
