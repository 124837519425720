import { lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { Route, Routes } from 'react-router'
import { Navigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from 'shared/firebase'
import { doc, DocumentReference, getDoc } from 'firebase/firestore'

import Box from 'components/Box'
import { Header } from 'components/Typography'
import rotateScreenIcon from 'shared/images/rotate-screen.svg'
import styled from 'shared/theme'

const Welcome = lazy(() => import('pages/Welcome'))
const About = lazy(() => import('./pages/Settings/About'))
const Profile = lazy(() => import('./pages/Settings/Profile'))
const DeleteAccount = lazy(
    () => import('./pages/Settings/Profile/DeleteAccount'),
)
const AddStamps = lazy(() => import('./pages/admin/AddStamps'))
const AdminHome = lazy(() => import('./pages/admin/AdminHome'))
const AdminLogin = lazy(() => import('./pages/admin/Login'))
const Login = lazy(() => import('./pages/Auth/Login'))
const Register = lazy(() => import('./pages/Auth/Register'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'))
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'))
const Home = lazy(() => import('./pages/Home'))
const App = lazy(() => import('./pages/App'))
const Settings = lazy(() => import('./pages/Settings'))

const AdminProtectedRoute = ({
    children,
}: {
    children: React.ReactElement
}) => {
    const [user, loading] = useAuthState(auth)
    const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined)
    const docRef =
        user?.uid !== undefined
            ? (doc(db, 'users', user.uid) as DocumentReference<{
                  isAdmin: boolean
              }>)
            : undefined

    useEffect(() => {
        const getUserInfo = async () => {
            if (docRef === undefined) {
                return
            }

            const userInfo = await getDoc(docRef)
            setIsAdmin(userInfo.data()?.isAdmin || false)
        }

        getUserInfo()
    }, [docRef])

    if (loading || (user !== null && isAdmin === undefined)) {
        return null
    }

    if (user === null) {
        return <Navigate to="/admin/login" replace />
    } else if (!isAdmin) {
        return <Navigate to="/app" replace />
    }

    return children
}

const AuthenticatedRoute = ({ children }: { children: React.ReactElement }) => {
    const [user, loading] = useAuthState(auth)

    if (loading) {
        return null
    }
    if (user === null) {
        return <Navigate to="/login" replace />
    }

    return children
}

const UnauthenticatedRoute = ({
    children,
}: {
    children: React.ReactElement
}) => {
    const [user, loading] = useAuthState(auth)

    if (loading) {
        return null
    }
    if (user !== null) {
        return <Navigate to="/app" replace />
    }

    return children
}

const Row = styled(Box)`
    gap: ${props => props.theme.space[6]};
`

const RotateScreen = () => {
    return (
        <Box width="100%" height="100%">
            <Row
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                height="100%"
            >
                <img src={rotateScreenIcon} />

                <Header>Моля завъртете екрана.</Header>
            </Row>
        </Box>
    )
}

const landscape = window.matchMedia('(orientation: landscape)')

const useIsLandscape = () => {
    const [isLandscape, setIsLandscape] = useState(false)

    useLayoutEffect(() => {
        const detectLandscape = (ev: MediaQueryListEvent) => {
            setIsLandscape(ev.matches)
        }

        landscape.addEventListener('change', detectLandscape)

        return () => {
            return landscape.removeEventListener('change', detectLandscape)
        }
    }, [])

    return isLandscape
}

const Router = () => {
    const isLandscape = useIsLandscape()
    if (isLandscape) {
        return <RotateScreen />
    }

    return (
        <Routes>
            <Route
                path="login"
                element={
                    <Suspense fallback={null}>
                        <UnauthenticatedRoute>
                            <Login />
                        </UnauthenticatedRoute>
                    </Suspense>
                }
            />
            <Route
                path="register"
                element={
                    <Suspense fallback={null}>
                        <UnauthenticatedRoute>
                            <Register />
                        </UnauthenticatedRoute>
                    </Suspense>
                }
            />
            <Route
                path="privacy-policy"
                element={
                    <Suspense fallback={null}>
                        <PrivacyPolicy />
                    </Suspense>
                }
            />
            <Route
                path="forgot-password"
                element={
                    <Suspense fallback={null}>
                        <ForgotPassword />
                    </Suspense>
                }
            />
            <Route
                path="reset-password"
                element={
                    <Suspense fallback={null}>
                        <ResetPassword />
                    </Suspense>
                }
            />
            <Route
                path="/"
                element={
                    <Suspense fallback={null}>
                        <Welcome />
                    </Suspense>
                }
            />
            <Route
                path="/"
                element={
                    <Suspense fallback={null}>
                        <AuthenticatedRoute>
                            <App />
                        </AuthenticatedRoute>
                    </Suspense>
                }
            >
                <Route path="app" element={<Home />} />

                <Route path="settings" element={<Settings />} />
            </Route>

            <Route
                path="settings/about"
                element={
                    <Suspense fallback={null}>
                        <AuthenticatedRoute>
                            <About />
                        </AuthenticatedRoute>
                    </Suspense>
                }
            />
            <Route
                path="settings/profile"
                element={
                    <Suspense fallback={null}>
                        <AuthenticatedRoute>
                            <Profile />
                        </AuthenticatedRoute>
                    </Suspense>
                }
            />
            <Route
                path="settings/delete-account"
                element={
                    <Suspense fallback={null}>
                        <AuthenticatedRoute>
                            <DeleteAccount />
                        </AuthenticatedRoute>
                    </Suspense>
                }
            />
            <Route
                path="admin/login"
                element={
                    <Suspense fallback={null}>
                        <AdminLogin />
                    </Suspense>
                }
            />
            <Route
                path="admin"
                element={
                    <Suspense fallback={null}>
                        <AdminProtectedRoute>
                            <AdminHome />
                        </AdminProtectedRoute>
                    </Suspense>
                }
            />
            <Route
                path="admin/add-stamps"
                element={
                    <Suspense fallback={null}>
                        <AdminProtectedRoute>
                            <AddStamps />
                        </AdminProtectedRoute>
                    </Suspense>
                }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}

export default Router
