import { Link } from 'react-router-dom'
import styled from 'shared/theme'

export const Header = styled.h1`
    font-size: ${props => props.theme.fontSizes[5]};
    line-height: ${props => props.theme.lineHeights[5]};
    font-weight: ${props => props.theme.fontWeight[6]};
`
export const Intro = styled.p`
    font-size: ${props => props.theme.fontSizes[4]};
    line-height: ${props => props.theme.lineHeights[5]};
    font-weight: ${props => props.theme.fontWeight[4]};
`
export const IntroBold = styled(Intro)`
    font-weight: ${props => props.theme.fontWeight[6]};
`
export const Description = styled.p`
    font-size: ${props => props.theme.fontSizes[2]};
    line-height: ${props => props.theme.lineHeights[4]};
    font-weight: ${props => props.theme.fontWeight[3]};
`
export const AdminHeader = styled.p`
    font-size: ${props => props.theme.fontSizes[7]};
    line-height: ${props => props.theme.lineHeights[7]};
    font-weight: ${props => props.theme.fontWeight[4]};
`
export const AdminEmail = styled(Intro)`
    font-weight: ${props => props.theme.fontWeight[6]};
`
export const ButtonText = styled.span`
    font-size: ${props => props.theme.fontSizes[3]};
    line-height: ${props => props.theme.lineHeights[5]};
    font-weight: ${props => props.theme.fontWeight[4]};
`
export const LinkStyled = styled(Link)`
    font-weight: ${props => props.theme.fontWeight[4]};
    font-size: ${props => props.theme.fontSizes[3]};
    line-height: ${props => props.theme.lineHeights[5]};
    color: ${props => props.theme.colors.darkBrown};
`
