import styled from 'shared/theme'
import {
    space,
    flexbox,
    color,
    border,
    position,
    size,
    PositionProps,
    SpaceProps,
    BorderProps,
    FlexboxProps,
    ColorProps,
    LayoutProps,
    layout,
    textAlign,
    TextAlignProps,
} from 'styled-system'

export type Props = SpaceProps &
    ColorProps &
    BorderProps &
    FlexboxProps &
    PositionProps &
    LayoutProps &
    TextAlignProps

const Box = styled.div<Props>`
    ${space}
    ${flexbox}
    ${color}
    ${border}
    ${position}
    ${size}
    ${layout}
    ${textAlign}
`

export default Box
