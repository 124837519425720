import baseStyled, { ThemedStyledInterface } from 'styled-components'

export const theme = {
    space: [
        '0px', // 0
        '4px', // 1
        '8px', // 2
        '12px', // 3
        '16px', // 4
        '18px', // 5
        '24px', // 6
        '32px', // 7
        '40px', // 8
        '56px', // 9
        '64px', // 10
        '100px', // 11
    ],
    fontSizes: [
        '10px', // 0
        '12px', // 1
        '14px', // 2
        '16px', // 3
        '18px', // 4
        '20px', // 5
        '26px', // 6
        '32px', // 7
        '40px', // 8
    ],
    lineHeights: [
        '12px', // 0
        '16px', // 1
        '18px', // 2
        '20px', // 3
        '22px', // 4
        '28px', // 5
        '34px', // 6
        '40px', // 7
    ],
    fontWeight: [
        100, // Thin
        200, // Ultra Light
        300, // Light
        400, // Regular
        500, // Medium
        600, // Semibold
        700, // Bold
        800, // Heavy
        900, // Black
    ],

    radii: {
        extraLarge: '8px',
        large: '6px',
        default: '4px',
        small: '2px',
    },

    iconSizes: ['16px', '20px', '24px', '32px', '40px', '72px'],

    colors: {
        redMain: '#CC1E41',
        darkBrown: '#1F0409',
        lightBrown: '#827070',
        lighterBrown: '#CDC2BF',
        white: '#FFFFFF',
    },
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1);',
}

export type Theme = typeof theme

const styled = baseStyled as ThemedStyledInterface<Theme>

export default styled
