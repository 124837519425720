import React from 'react'
import ReactDOM from 'react-dom/client'
import { init as initSentry } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { register } from 'serviceWorkerRegistration'
import App from './App'
import 'index.css'

initSentry({
    dsn: 'https://362bd3fd9ef34f8f9e1a20ff91ec9ae5@o484616.ingest.sentry.io/4504168340717568',
    release: process.env.npm_package_version,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)

register()
